<template>
  <div class="about container">
    <div class="row align-items-center">
      <div class="col-12 col-md-10 col-lg-6">
        <h1 class="mb-5 text-title">{{ $t('page.about') }}</h1>
        <p v-for="p in $t('presentation')" :key="p"> {{ p }} </p>
        <div class="row justify-content-center">
          <div class="col-auto">
            <button
              type="button"
              class="btn btn-settings my-3"
              v-on:click="openLink('linkedIn')"
            >
              <i class="lab la-linkedin-in"></i>
            </button>
          </div>
          <div class="col-auto">
            <button
              type="button"
              class="btn btn-settings my-3"
              v-on:click="openLink('github')"
            >
              <i class="lab la-github"></i>
            </button>
          </div>
          <!-- <div class="col-auto">
            <button
              type="button"
              class="btn btn-settings my-3"
            >
              <i class="lab la-jsfiddle"></i>
            </button>
          </div>
          <div class="col-auto">
            <button
              type="button"
              class="btn btn-settings my-3"
            >
              <i class="lab la-instagram"></i>
            </button>
          </div>
          <div class="col-auto">
            <button
              type="button"
              class="btn btn-settings my-3"
            >
              <i class="lab la-youtube"></i>
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { changeBackground } from "@/assets/js/background.js";
export default {
  name: 'About',
  created() {
    changeBackground("about");
  },
  data() {
    return {
      socialMedias: {
        linkedIn: "https://www.linkedin.com/in/walmir-silva-dos-santos/",
        github: "https://github.com/WSilva99"
      }
    }
  },
  methods: {
    openLink(link) {
      window.open(this.socialMedias[link], '_blank');
    }
  },
}
</script>